import { docReady, qsa } from "@js/libs/helpers";

docReady(() => {
  const homeSlides = [];
  qsa('[data-homeslide]', (slide, i) => {
    homeSlides.push({
      i, slide
    })
  });

  if (homeSlides.length) {

    let index = 0;

    const activate = n => {
      homeSlides.forEach(({ i, slide }) => {
        if (n === i) {
          slide.classList.add('active')
        } else {
          slide.classList.remove('active')
        }
      })

      let nextIndex = n + 1;
      if(nextIndex < homeSlides.length) {
        let nextSlide = homeSlides[nextIndex].slide.querySelector('img.prelazyload');
        if(nextSlide) {
          nextSlide.classList.add('lazyload');
          nextSlide.classList.remove('prelazyload');
        }
      }
    }

    activate(index)

    const page = () => {

      requestAnimationFrame(() => {

        index += 1

        if (index >= homeSlides.length) {
          index = 0;
        }

        activate(index)
      })
    }

    setInterval(page, 5000)

  }
});