import * as focusTrap from 'focus-trap';
import { docReady, qsa } from "@js/libs/helpers";



docReady(() => {

  let isOpen = false;

  const overlay = document.getElementById('sign-up-overlay')
  const bg = document.getElementById('sign-up-background')
  const closeButton = document.getElementById('sign-up-close')

  if (!overlay) return

  const trap = focusTrap.createFocusTrap(overlay, {
    escapeDeactivates: true,
    onDeactivate: () => {
      close()
    }
  })

  const update = () => {

    if (isOpen) {
      overlay.classList.remove('invisible')
      trap.activate()
    } else {
      overlay.classList.add('invisible')
      trap.deactivate()
    }
  }

  const open = () => {
    isOpen = true
    update()
  }

  const close = () => {
    isOpen = false
    update()
  }

  const toggle = () => {
    isOpen = !isOpen
    update()
  }

  qsa('[data-open-overlay]', btn => {
    btn.addEventListener('click', e => {
      e.preventDefault();
      open()
    })
  })

  // listeners on overlay itself...
  if (bg) bg.addEventListener('click', close)
  if (closeButton) closeButton.addEventListener('click', close)


})
