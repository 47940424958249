import { docReady } from "@js/libs/helpers";

// the limit will be either window height
// OR this max. You should ensure that
// .slides-height class behaves the same, 
// currently just on mobile.
const maxH = 600;

// Set to a percentage of the slides height, 
// so 0.8 would mean when it scrolls past 80%
// of the slides, it'll change.
const percentScrolled = 0.8;

const loop = () => {
  
  // Fire almost constantly, but not constantly.
  requestAnimationFrame( () => setTimeout( () => requestAnimationFrame( loop ), 50 ) )

  let h;

  // Pages other than homepage required a different height to trigger styles (opacity + invisible)
  if (document.body.classList.contains('mobile-header')) {
    h = 20;
  } else {
    h = window.innerHeight > maxH ? maxH : window.innerHeight;
  }

  if (window.pageYOffset > ( h * percentScrolled ) ) {
    document.body.classList.remove('invert-nav')
  } else if (window.pageYOffset <= window.innerHeight) {
    document.body.classList.add('invert-nav')
  }
}

docReady(() => {
  if (document.body.classList.contains('invert-nav')) {
    requestAnimationFrame(loop)
  }
})