import {  docReady, qsa } from "@js/libs/helpers";
const getScrollsnap = () => import("@js/parts/scrollsnap");




docReady( () => {
  const carousels = document.querySelectorAll('[data-scrollsnap]');

	if ( carousels.length ) {
		getScrollsnap().then( scrollsnap => {
			carousels.forEach( c => scrollsnap.default(c) );
		});
  }
})